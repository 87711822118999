import React from "react";
import { graphql } from "gatsby";
import { useTranslation } from "@w3f/gatsby-theme-w3f/src/utils/i18n";
import SEO from "@w3f/gatsby-theme-w3f/src/components/site/SEO";
import Layout from "components/site/Layout";
import Container from "layouts/Container";
import Hero from "components/Hero";
import Button from "elements/Button";
import useCopyToClipboard from "hooks/use-copy-to-clipboard";

const SecurityReportPage = ({ data: { page, footerImage } }) => {
  const { t } = useTranslation();
  const { publicKey, seo } = page.frontmatter;
  const meta = seo ? { ...seo } : {}; // In case the seo object is null

  const [value, copy] = useCopyToClipboard();
  return (
    <Layout footerImage={footerImage}>
      <SEO {...meta} />
      <Container className="container--t-margin-sm">
        <Hero
          title={t("Report a Security Issue")}
          content={
            <p className="t3">
              At Web3 Foundation (W3F) we encourage responsible security investigation and reporting.
            </p>
          }
          asideTitle="What to report"
          asideContent={
            <>
              <p className="t-md">Contact us if you have discovered security issues in any of the following:</p>
              <br />
              <ul>
                <li>Kusama/Polkadot protocol design and implementation</li>
                <li>Services, web-based and otherwise, deployed by W3F</li>
                <li>W3F network and infrastructure</li>
              </ul>
            </>
          }
        />
      </Container>
      <Container className="container--b-margin-md">
        <div className="t-text t-max-width">
          <h2 className="t-title-2">How to report</h2>
          <p>
            To report a security issue send an email to{" "}
            <a
              href="mailto:security-report@web3.foundation"
              className="o-link-bold"
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              security-report@web3.foundation
            </a>
            .<br />
            Consider encrypting your message using the PGP key embedded below.
          </p>

          <aside>
            <details>
              <summary>
                <h3 className="t-bold">PGP Public Key</h3>
              </summary>
              <main>
                <pre>
                  <code>{publicKey}</code>
                </pre>
              </main>
              <footer>
                <Button className="" onClick={() => copy(publicKey)} gray>
                  {value ? "Copied!" : "Copy Key"}
                </Button>
              </footer>
            </details>
          </aside>
          <p className="t-bold">
            To facilitate reproduction of reported issues, we encourage you to include the following information if
            possible:
          </p>
          <br />
          <ul className="">
            <li>Description of the issue</li>
            <li>Description of the issue’s potential security impact</li>
            <li>The affected resource. e.g. URL, GitHub code snippet, transaction</li>
            <li>Ideally a proof-of-concept that demonstrates the issue</li>
          </ul>

          <h2 className="t-title-2">Our process</h2>
          <p>
            Upon receiving your report, W3F will assess the issue and reach out to ask for additional information and/or
            provide assessment. Any reported security issues may be eligible for rewards depending on W3F’s
            determination of their novelty and the severity of their potential impact.
          </p>
        </div>
      </Container>
    </Layout>
  );
};

export default SecurityReportPage;

export const query = graphql`
  query ($language: String!) {
    ...Locales
    page: markdownRemark(
      fields: { langKey: { eq: $language } }
      fileAbsolutePath: { regex: "//pages/security-report.md/" }
    ) {
      html
      frontmatter {
        publicKey: public_key
        seo {
          ...Seo
        }
      }
      fields {
        langKey
        slug
      }
    }
    footerImage: file(name: { eq: "home2-large" }) {
      ...BgImage
    }
  }
`;
