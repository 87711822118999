import { useState } from "react";

function useCopyToClipboard() {
  const [copiedText, setCopiedText] = useState(null);

  const copy = async (text) => {
    if (!navigator || !navigator.clipboard) {
      console.warn("Clipboard not supported");
      return false;
    }

    // Copy from clipboard with fallback
    async function copyTextToClipboard(text) {
      if ("clipboard" in navigator) {
        return await navigator.clipboard.writeText(text);
      } else {
        return document.execCommand("copy", true, text);
      }
    }

    // Try to save to clipboard then save it in the state if worked
    try {
      await copyTextToClipboard(text);
      setCopiedText(text);
      return true;
    } catch (error) {
      console.warn("Copy failed", error);
      setCopiedText(null);
      return false;
    }
  };

  return [copiedText, copy];
}

export default useCopyToClipboard;
